import {ActivityEntity, ActivityType} from "../entities/Acitivity";
import {Response} from "../entities/Response";
import React, {createContext, FC, ReactNode} from "react";
import API from "../helpers/API";

export interface IActivityService {
    getActivities(code: string): Promise<ActivityEntity[]>
    createActivity(code: string, type: ActivityType): Promise<Response>
}

export interface ActivityServiceProps {
    children: ReactNode
}

export const ActivityServiceContext = createContext<IActivityService | undefined>(undefined);

const ActivityService : FC<ActivityServiceProps> = (props: ActivityServiceProps) => {

    const activityService = {
        getActivities(code: string): Promise<ActivityEntity[]> {
            return fetch(`${API}/api/Activity?code=${code}`)
                .then(r => r.json())
        },
        createActivity(code: string, type: ActivityType): Promise<Response> {
            return fetch(`${API}/api/Activity`, {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({
                    code: code,
                    activityType: type
                })})
                .then(r => r.json())
        }
    }

    return (
        <ActivityServiceContext.Provider value={activityService}>
            {props.children}
        </ActivityServiceContext.Provider>
    )
}

export default ActivityService;
