import {useSearchParams} from "react-router-dom";
import {useCallback, useContext, useEffect, useRef, useState} from "react";
import {ActivityServiceContext} from "../services/ActivityService";
import {ActivityType} from "../entities/Acitivity";
import {Card, CardBody, Col, Row} from "reactstrap";

import "../styles/Personal.css";
import useOnScreen from "../helpers/OnScreen";

export default function Personal() {
    const [query] = useSearchParams();
    const activityService = useContext(ActivityServiceContext);
    const ref = useRef<HTMLHRElement>(null)
    const isVisible = useOnScreen(ref)

    let code = query.get("code");
    let [sent, setSent] = useState<ActivityType[]>([]);

    const sendActivity = useCallback((type: ActivityType) => {
        if (code && activityService && sent.indexOf(type) < 0) {
            setSent(prevState => [type, ...prevState])
            activityService.createActivity(code, type);
        }
    }, [sent, code, activityService]);

    const userExiting = useCallback((e: any) => {
        e.preventDefault();
        sendActivity(ActivityType.Exit);
    }, [sendActivity]);

    useEffect(() => {
        window.addEventListener('beforeunload', userExiting)
        return () => {
            window.removeEventListener('beforeunload', userExiting)
        }
    }, [userExiting])

    useEffect(() => {
        sendActivity(ActivityType.Entry);
    }, [sendActivity])

    useEffect(() => {
        if (isVisible) {
            sendActivity(ActivityType.Scroll);
        }
    }, [isVisible, sendActivity])

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function videoStarted() {
       sendActivity(ActivityType.Video);
    }

    return (
        <div className="personal">
            {/*<Card>
                <CardBody onClick={videoStarted}>
                    Video
                </CardBody>
            </Card>*/}
            <Card>
                <CardBody>
                    <Row>
                        <Col xl="5">
                            <div className="d-table full p-3">
                                <div className="d-table-cell middle">
                                    <div className="me shadow">
                                        <img className="me" alt="Me" src="/images/me.jpg"/>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col className="p-4">
                        <h2>Hi!</h2>
                            <h5>My name is Mateusz Lapies I will shortly describe myself and my skills</h5>
                            <p>My story connected with programming and indepth look into how computers work started with managing of a Arma 3 game server. Well prepared and thought through server caused great appreciation from players which in turn fueled my need to expand my knowledge. This happened while I was in high school, which made a choice of what I would like to do professionally in the future, fairly easy.</p>
                            <p>This way, three years later I made it to the Technical University of Lodz, where I quickly got bored and started looking for something new to do. At the beginning of 2nd year of studies I have managed to get software engineer internship at Accenture, where I work to this day. There I had an opportunity to work with wide range of Microsoft products and Agile framework.</p>
                            <p>Despite having to study and work pretty much at the same time, in the middle of the 2019 I have established this company. My goal was to allow my self further development of myself and to provide corpo like services for small and medium businesses for a reasonable price.</p>
                            <p>Currently I have three clients for whom I provide different levels of services. From managing on premises infrastructure to software engineering.</p>
                        </Col>
                    </Row>
                    <hr />
                    <Row className="text-center split mb-5">
                        <Col className="right mt-4">
                            <h4>
                                <i className="bi bi-laptop me-2" />Work experience
                            </h4>
                            <Row>
                                <Col className="mb-3">
                                    <div>
                                        <img className="logo p-4" alt="Stibo Systems" src="/images/Stibo_Logo_Skagerrak_RGB.svg" />
                                    </div>
                                    <div><h5>Stibo Systems</h5></div>
                                    <div><b>Fullstack Developer</b></div>
                                    <div>09/2023 - now</div>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="mb-3">
                                    <div>
                                        <img className="logo p-4" alt="Netcompany" src="/images/netcompany.png" />
                                    </div>
                                    <div><h5>Netcompany</h5></div>
                                    <div><b>IT Consultant</b></div>
                                    <div>04/2022 - 08/2023</div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div>
                                        <img className="logo p-3" alt="Accenture" src="/images/accenture.png"  />
                                    </div>
                                    <div><h5>Accenture</h5></div>
                                    <div><b>Software Engineer</b></div>
                                    <div>04/2018 - 03/2022</div>
                                </Col>
                            </Row>
                        </Col>
                        <Col className="left mt-4">
                            <h4>
                                <i className="bi bi-mortarboard me-2" />Education
                            </h4>
                            <Row>
                                <Col className="mb-3">
                                    <div>
                                        <img className="logo p-2" alt="Politechnika Krakowska" src="/images/politechnika_krakowska.png" />
                                    </div>
                                    <div><h5>Technical University of Cracow</h5></div>
                                    <div><b>MSc. Computer Science</b></div>
                                    <div>Cybersecurity</div>
                                    <div>2021 - 2023</div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div>
                                        <img className="logo" alt="Politechnika Łódzka" src="/images/politechnika_lodzka.png" />
                                    </div>
                                    <div><h5>Technical University of Lodz</h5></div>
                                    <div><b>BSc. Computer Science</b></div>
                                    <div>Technologies of Games and Computer Simulations</div>
                                    <div>2016 - 2020</div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <hr />
                    <Row className="text-center mb-3">
                        <Col className="technologies">
                            <Row>
                                <Col>
                                    <h4>
                                        <i className="bi bi-boxes me-2" />Technologies
                                    </h4>
                                </Col>
                            </Row>
                            <Row className="split">
                                <Col className="right">
                                    <Row>
                                        <Col>
                                            <h5>
                                                <i className="bi bi-hdd-network me-1" />Backend
                                            </h5>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="mb-4">
                                            <div>
                                                <img alt=".NET" src="/icons/csharp-original.svg" />
                                            </div>
                                            <div>.NET</div>
                                        </Col>
                                        <Col className="mb-4">
                                            <div>
                                                <img alt="Java" src="/icons/java-original.svg" />
                                            </div>
                                            <div>Java</div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="left">
                                    <Row>
                                        <Col>
                                            <h5>
                                                <i className="bi bi-display me-1" />Frontend
                                            </h5>
                                            <Row>
                                                <Col className="mb-4">
                                                    <div>
                                                        <img alt="React" src="/icons/react-original.svg" />
                                                    </div>
                                                    <div>React</div>
                                                </Col>
                                                <Col className="mb-4">
                                                    <div>
                                                        <img alt="Angular" src="/icons/angularjs-original.svg" />
                                                    </div>
                                                    <div>Angular</div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col>
                                    <h5>
                                        <i className="bi bi-database me-1" />Databases
                                    </h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="mb-4 ps-4 pe-4">
                                    <div>
                                        <img alt="SQL" src="/icons/microsoftsqlserver-plain.svg" />
                                    </div>
                                    <div>MSSQL</div>
                                </Col>
                                <Col className="mb-4 ps-4 pe-4">
                                    <div>
                                        <img alt="Postgres" src="/icons/postgresql-original.svg" />
                                    </div>
                                    <div>Postgres</div>
                                </Col>
                                <Col className="mb-4 ps-4 pe-4">
                                    <div>
                                        <img alt="MongoDB" src="/icons/mongodb-original.svg" />
                                    </div>
                                    <div>MongoDB</div>
                                </Col>
                                <Col className="mb-4 ps-4 pe-4">
                                    <div>
                                        <img alt="Redis" src="/icons/redis-original.svg" />
                                    </div>
                                    <div>Redis</div>
                                </Col>
                            </Row>
                            <hr />
                            <Row className="split">
                                <Col className="right">
                                    <Row>
                                        <Col>
                                            <h5>
                                                <i className="bi bi-chevron-double-right me-1" />DevOps
                                            </h5>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="mb-4">
                                            <div>
                                                <img alt="Azure" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/azure/azure-original.svg" />
                                            </div>
                                            <div>Azure DevOps</div>
                                        </Col>
                                        <Col className="mb-4">
                                            <div>
                                                <img alt="Github" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/github/github-original.svg" />
                                            </div>
                                            <div>Github Actions</div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="left">
                                    <Row>
                                        <Col>
                                            <h5>
                                                <i className="bi bi-box-seam me-1" />Containers
                                            </h5>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="mb-4">
                                            <div>
                                                <img alt="Docker" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/docker/docker-original.svg" />
                                            </div>
                                            <div>Docker</div>
                                        </Col>
                                        <Col className="mb-4">
                                            <div>
                                                <img alt="Kubernetes" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/kubernetes/kubernetes-plain.svg" />
                                            </div>
                                            <div>Kubernetes</div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <hr ref={ref} />
                            <Row>
                                <Col>
                                    <h5>
                                        <i className="bi bi-tools me-1" />Tools
                                    </h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div>
                                        <img alt="Visual Studio" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/visualstudio/visualstudio-plain.svg" />
                                    </div>
                                    <div>Visual Studio</div>
                                </Col>
                                <Col>
                                    <div>
                                        <img alt="Intelij IDEA" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/intellij/intellij-original.svg" />
                                    </div>
                                    <div>Intellij IDEA</div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </div>
    )
}
