import {Activities, ActivityEntity} from "./Acitivity";
import {Badge, Card, CardBody, CardHeader, Col, Collapse, Row} from "reactstrap";
import Delete from "../components/Delete";
import Download from "../components/Download";
import {useState} from "react";

export interface CodeEntity {
  id: number
  guid: string
  company: string
  created: string,
  activities: ActivityEntity[]
}

export interface CodeProps {
  code: CodeEntity,
  callback: Function
}

export interface CodesProps {
  codes: CodeEntity[],
  callback: Function
}

export function Code(props: CodeProps) {
  let [open, setOpen] = useState(false);
  const timestamp = new Date(props.code.created);

  function toggle() {
    setOpen(!open);
  }

  return (
    <Card className="mb-3">
      <CardHeader onClick={toggle}>
        <Row>
          <Col>
            {props.code.company}
            {props.code.activities.length > 0 ? (
              <Badge className="ms-2">{props.code.activities.length}</Badge>
            ) : undefined}
          </Col>
          <Col>
            {timestamp.toLocaleDateString()} {timestamp.toLocaleTimeString()}
          </Col>
          <Col xs="3">
            <Delete id={props.code.id} callback={props.callback} className="float-end"/>
            <Download id={props.code.id} className="float-end me-3"/>
          </Col>
        </Row>
      </CardHeader>
      {props.code.activities.length > 0 ? (
        <Collapse isOpen={open}>
          <CardBody>
            <Activities activities={props.code.activities} />
          </CardBody>
        </Collapse>
      ) : undefined}
    </Card>
  )
}

export function Codes(props: CodesProps) {
  return (
    <>
      {
        props.codes.map((code, index) => (
          <Code key={index} code={code} callback={props.callback}/>
        ))
      }
    </>
  )
}
