import {Container} from "reactstrap";

import "../styles/Body.css";

export default function Body(props : any) {

    return (
        <Container className="body">
            {props.children}
        </Container>
    )
}
