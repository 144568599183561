const dev = {
  authority: "http://localhost:8080/realms/iffective",
  client_id: "web",
  redirect_uri: "http://localhost:3000/admin"
};

const preprod = {
  authority: "https://dev.iffective.dk/auth/realms/iffective",
  client_id: "web",
  redirect_uri: "https://dev.iffective.dk/admin"
}

const prod = {
  authority: "https://iffective.dk/auth/realms/iffective",
  client_id: "web",
  redirect_uri: "https://iffective.dk/admin"
};

let Keycloak = dev;

if (process.env.REACT_APP_ENVIRONMENT === "preproduction") {
  Keycloak = preprod;
} else if (process.env.REACT_APP_ENVIRONMENT === "production") {
  Keycloak = prod;
}

export default Keycloak;
