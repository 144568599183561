import {Col, Row, Spinner} from "reactstrap";

export default function Loading() {
  return (
    <Row>
      <Col className="text-center">
        <Spinner/>
      </Col>
    </Row>
  )
}
