import {useAuth} from "react-oidc-context";
import {useState} from "react";
import {Button, Spinner} from "reactstrap";
import API from "../helpers/API";

interface DeleteProps {
  id: number
  callback: Function,
  className: string
}

export default function Delete(props: DeleteProps) {
  const auth = useAuth();
  const [processing, setProcessing] = useState(false);

  function onClick() {
    fetch(`${API}/api/Code/${props.id}`, {
      method: "DELETE",
      headers: {
        "Authorization": auth.user?.token_type + " " + auth.user?.access_token
      }
    })
      .then(r => r.json())
      .then(() => {
        props.callback();
        setProcessing(false);
      })
      .catch(() => setProcessing(false));
  }

  function loading(isLoading: boolean) {
    if (isLoading) {
      return <Spinner size="sm" className="me-1"/>
    } else {
      return <i className="bi bi-file-earmark-x me-1"/>
    }
  }

  return (
    <Button
      size="sm"
      color="danger"
      onClick={onClick}
      disabled={processing}
      className={props.className}>
      {loading(processing)}Delete
    </Button>
  );
}
