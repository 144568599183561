import {Table} from "reactstrap";

export enum ActivityType {
    Entry,
    Video,
    Scroll,
    Exit
}

export interface ActivityEntity {
    id: number,
    activityType: ActivityType,
    created: string
}

export interface ActivityProps {
    activity: ActivityEntity
}

export interface ActivitiesProps {
  activities: ActivityEntity[]
}

export function Activity(props: ActivityProps) {
  function activityType(type: ActivityType) {
    switch (type) {
      case ActivityType.Entry: {
        return "Entry";
      }

      case ActivityType.Video: {
        return "Video"
      }

      case ActivityType.Scroll: {
        return "Scrolled"
      }

      case ActivityType.Exit: {
        return "Exit"
      }
    }
  }

  const timestamp = new Date(props.activity.created);

  return (
    <tr>
      <th>
        {props.activity.id}
      </th>
      <td>
        {activityType(props.activity.activityType)}
      </td>
      <td>
        {timestamp.toLocaleDateString()} {timestamp.toLocaleTimeString()}
      </td>
    </tr>
  )
}

export function Activities(props: ActivitiesProps) {
  function sort(a: ActivityEntity, b: ActivityEntity) {
    return Date.parse(b.created) - Date.parse(a.created);
  }

  return (
    <Table>
      <thead>
      <tr>
        <th>
          #
        </th>
        <th>
          Activity Type
        </th>
        <th>
          Timestamp
        </th>
      </tr>
      </thead>
      <tbody>
      {
        props.activities.sort(sort).map((activity, index) => (
          <Activity key={index} activity={activity}/>
        ))
      }
      </tbody>
    </Table>
  )
}
