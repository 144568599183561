import "../styles/Home.css";

export default function Home() {
  return (
    <>
      <div className="iffective">
        <div className="cell">
          <h2 className="font-weight-bold">Informatization does not have to be expensive!</h2>
          <h5>it just has to be effective</h5>
          <p>Let's see how Your company can be more effective!</p>
        </div>
      </div>
    </>
  )
}
