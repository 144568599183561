import {Button, Spinner} from "reactstrap";
import {useAuth} from "react-oidc-context";
import {useState} from "react";
import API from "../helpers/API";

interface DownloadProps {
  id: number,
  className: string
}

export default function Download(props: DownloadProps) {
  const auth = useAuth();
  const [processing, setProcessing] = useState(false);

  function onClick() {
    fetch(`${API}/api/Code/${props.id}`, {
      headers: {
        "Authorization": auth.user?.token_type + " " + auth.user?.access_token
      }
    })
      .then(r => r.blob())
      .then(b => {
        const url = window.URL.createObjectURL(new Blob([b]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'cv.pdf');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setProcessing(false);
      })
      .catch(() => setProcessing(false));
  }

  function loading(isLoading: boolean) {
    if (isLoading) {
      return <Spinner size="sm" className="me-1"/>
    } else {
      return <i className="bi bi-file-pdf me-1"/>
    }
  }

  return (
    <Button
      size="sm"
      onClick={onClick}
      disabled={processing}
      className={props.className}>
      {loading(processing)}Download
    </Button>
  );
}
