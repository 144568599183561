import {useEffect} from "react";
import {hasAuthParams, useAuth} from "react-oidc-context";
import Loading from "./Loading";

export default function Protected(props: any) {
  const auth = useAuth();
  useEffect(() => {
    if (!hasAuthParams() &&
      !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
      auth.signinRedirect();
    }
  }, [auth.isAuthenticated, auth.activeNavigator, auth.isLoading, auth.signinRedirect, auth]);

  if (auth.isAuthenticated) {
    return props.children;
  } else {
    return <Loading/>
  }
}
