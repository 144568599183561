import {Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink} from "reactstrap";
import {useState} from "react";

import "../styles/Header.css";
import {useLocation} from "react-router-dom";

export default function Header() {
    const location = useLocation();
    let [open, setOpen] = useState(false);

    function toggle() {
        setOpen(!open)
    }

    return (
        <Navbar container="lg" dark expand className="header mb-5 pb-0">
            <NavbarBrand href="/">
                <img alt="iffective" src="/images/logo.png" />
            </NavbarBrand>
            <NavbarToggler onClick={toggle} />
            <Collapse isOpen={open} navbar>
                <Nav className="me-auto" navbar>
                    <NavItem>
                        <NavLink href="/" active={location.pathname === "/"} disabled>iffective</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="/personal" active={location.pathname === "/personal"}>personal</NavLink>
                    </NavItem>
                </Nav>
            </Collapse>
        </Navbar>
    )
}
