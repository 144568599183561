import {Col, Container, Row} from "reactstrap";
import React from "react";

import "../styles/Footer.css";
import {useLocation} from "react-router-dom";

export default function Footer() {
  const location = useLocation();

  return (
    <Container fluid className="footer pt-5 mt-5">
      <Container>
        <Row>
          <Col>
            <div>
              <h4>
                iffective
              </h4>
              {location.pathname === "/" ? (
                <>
                  <li className="list-unstyled">
                    VAT: DK44872242
                  </li>
                </>
              ) : undefined}
            </div>
          </Col>
          <Col>
            {location.pathname === "/" ? (
              <div>
                <h5>
                  <i className="bi bi-geo me-2" />Company Address
                </h5>
                <ul className="ps-4">
                  <li className="list-unstyled">
                    Ikarosvej 40
                  </li>
                  <li className="list-unstyled">
                    9210, Aalborg
                  </li>
                  <li className="list-unstyled">
                    Denmark
                  </li>
                </ul>
              </div>
            ) : undefined}
          </Col>
          <Col>
            <div>
              <h5>
                <i className="bi bi-chat-dots me-2" />Contact
              </h5>
              <ul className="footerInfo">
                <li className="list-unstyled">
                  <i className="bi bi-envelope me-2" />
                  <a href={"mailto:mateusz.lapies@iffective.dk"}>mateusz.lapies@iffective.dk</a>
                </li>
                <li className="list-unstyled">
                  <i className="bi bi-phone me-2" />
                  <a href="tel:+4571511651">+45 71 51 16 51</a>
                </li>
                {location.pathname === "/" ? (
                  <li className="list-unstyled">
                    <i className="bi bi-github me-2" />
                    <a href="https://github.com/orgs/iffective-dk">Github profile</a>
                  </li>
                ) : (
                  <li className="list-unstyled">
                    <i className="bi bi-github me-2" />
                    <a href="https://github.com/mateuszlapies">Github profile</a>
                  </li>
                )}
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
      <hr/>
      <div className="text-center p-3 mb-3">
        <Container fluid>
          &copy; {new Date().getFullYear()} Copyright: <a href="https://iffective.dk"> iffective.dk</a>
        </Container>
      </div>
    </Container>
  )
}
