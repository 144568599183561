import {Button, Col, Form, FormGroup, Input, Label, Offcanvas, OffcanvasBody, OffcanvasHeader, Row} from "reactstrap";
import {useState} from "react";
import {useAuth} from "react-oidc-context";
import API from "../helpers/API";

interface GenerateProps {
  callback: Function
}

export default function Generate(props: GenerateProps) {
  const auth = useAuth();
  let [open, setOpen] = useState(false);
  let [processing, setProcessing] = useState(false);

  function toggle() {
    setProcessing(false)
    setOpen(!open)
  }

  function onSubmit(e: any) {
    setProcessing(true)
    e.preventDefault();
    fetch(`${API}/api/Code`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": auth.user?.token_type + " " + auth.user?.access_token
      },
      body: JSON.stringify({company: e.target.company.value})
    })
      .then(r => r.blob())
      .then(b => {
        const url = window.URL.createObjectURL(new Blob([b]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'cv.pdf');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        toggle();
        setProcessing(false);
        props.callback();
      })
      .catch(() => setProcessing(false));
  }

  return (
    <>
      <Row className="mb-3">
        <Col xs="10"/>
        <Col>
          <Button
            color="primary"
            onClick={toggle}
            disabled={open}
            className="float-end"><i className="bi bi-qr-code me-2"/>Generate Code
          </Button>
        </Col>
      </Row>
      <Offcanvas
        isOpen={open}
        toggle={toggle}
        direction="end"
      >
        <OffcanvasHeader toggle={toggle}>
          Generate Code
        </OffcanvasHeader>
        <OffcanvasBody>
          <Form onSubmit={onSubmit}>
            <FormGroup>
              <Label for="company">
                Company
              </Label>
              <Input
                required
                type="text"
                name="company"
                placeholder="Name of the company"
              />
            </FormGroup>
            <Button disabled={processing} type="submit" color="primary">Submit</Button>
          </Form>
        </OffcanvasBody>
      </Offcanvas>
    </>
  )
}
