import {Card, CardBody, Col, Row} from "reactstrap";
import {CodeEntity, Codes} from "../entities/Code";
import {useCallback, useEffect, useState} from "react";
import Generate from "../components/Generate";
import {useAuth} from "react-oidc-context";
import API from "../helpers/API";

import "../styles/Admin.css";
import {useLocation, useNavigate} from "react-router-dom";

export default function Admin() {
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  let [codes, setCodes] = useState<CodeEntity[]>([]);

  useEffect(() => {
    if (location.search !== "") {
      navigate("/admin");
    }
  }, [location.search, navigate])
  
  const load = useCallback(() => {
    if (auth.isAuthenticated && auth.user) {
      fetch(`${API}/api/Code`, {
        headers: {"Authorization": auth.user?.token_type + " " + auth.user?.access_token}
      })
        .then(r => r.json())
        .then(j => setCodes(j));
    }
  }, [auth.isAuthenticated, auth.user])

  useEffect(() => {
      load();
  }, [load])

  function mapper(c: CodeEntity[]) {
    if (c.length > 0) {
      return <Codes codes={c} callback={load} />
    } else {
      return (
        <Row>
          <Col className="text-center">
            There is no codes to be displayed
          </Col>
        </Row>
      )
    }
  }

  return (
    <Card className="admin">
      <CardBody>
        <Row>
          <Col>
            <h4 className="m-0">Administration</h4>
          </Col>
          <Col xs="2">
            <Generate callback={load} />
          </Col>
        </Row>
        <hr/>
        <Row>
          <Col>
            {mapper(codes)}
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}
