import React from 'react';
import Header from "./components/Header";
import Body from "./components/Body";
import Footer from "./components/Footer";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import Personal from "./pages/Personal";
import NotFound from "./pages/NotFound";
import ActivityService from "./services/ActivityService";
import Admin from "./pages/Admin";
import {AuthProvider} from "react-oidc-context";
import Protected from "./components/Protected";
import Keycloak from "./helpers/Keycloak";

function App() {
  return (
    <BrowserRouter>
      <AuthProvider {...Keycloak}>
        <Header/>
        <Body>
          <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/personal/:code?" element={
              <ActivityService>
                <Personal/>
              </ActivityService>
            }/>
            <Route path="/admin" element={
              <Protected>
                <Admin/>
              </Protected>
            }/>
            <Route path="*" element={<NotFound/>} />
          </Routes>
        </Body>
        <Footer/>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
